@import url("https://fonts.googleapis.com/css2?family=Rubik");

body {
  font-family: "Rubik", sans-serif;
  margin: 0;
  direction: rtl;
}
input,
textarea {
  font-family: "Rubik", sans-serif !important;
}

input[type="tel"]{
  direction: rtl;
}

.dir_ltr{
  direction: ltr;
}
.only_mobile{
  @media (min-width: 1100px) {
    display: none;
  }
}

.notification-container {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  > div > div {
    background: #000000d4;
    padding: 10px;
    width: fit-content;
    color: white;
    border-radius: 10px;
    margin: auto;
  }
  @media (min-width: 1100px) {
    font-size: 18px;
  }
}

.loader-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000000b0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1100px) {
    top: -110px;
    left: calc(-50vw + 350px);
    z-index: 10000;
  }
}

header {
  direction: ltr;
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 63px;
  background: #000000;
  &[step="0"] {
    justify-content: center;
    background: white;
  }
  @media (min-width: 1100px) {
    &.abs {
      position: absolute;
      width: calc(100vw - 0px);
      top: -110px;
      left: calc(-50vw + 350px);
    }
  }
  @media (max-width: 1100px) {
    &[desktop="1"] {
      display: none;
    }
  }
}
footer {
  margin: auto;
  padding: 17px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #000000;
  a {
    color: inherit;
    text-decoration: none;
  }
  span {
    display: block;
  }
  @media (min-width: 1100px) {
    font-size: 16px;
    br {
      // display: none;
      content: "";
      &:after {
        content: " ";
      }
    }
    &.abs {
      position: absolute;
      width: calc(100vw - 34px);
      bottom: -90px;
      left: calc(-50vw + 50%);
    }
  }
}

.footer_block{
  display: flex;
    gap: 30px;
    justify-content: center;
    @media (max-width: 1100px) {
      flex-direction: column-reverse;
      gap: 15px;
    }
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 1100px) {
    min-height: calc(100vh - 320px);
    max-width: calc(700px - 200px);
    margin: 110px auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: white;
    padding: 50px 100px 50px;
    border-radius: 37px;
    &[step="0"] {
      margin: 0 0 0 auto;
      min-height: calc(100vh - 50px);
      max-width: calc(45vw - 240px);
      padding: 50px 120px 0px;
      border-radius: 0;
      filter: none;
      @media (min-width: 1300px) and (max-width: 1550px) {
        max-width: calc(55vw - 240px);
      }
      .error_msg {
        top: 2rem;
      }
    }
  }
}

.desktop {
  @media (min-width: 1100px) {
    overflow: hidden;
    background-image: url(../Images/desktop_all.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 98%;
    &[step="0"] {
      background-image: url(../Images/desktop_0.jpg);
      background-repeat: no-repeat;
      background-position: left;
      background-size: contain;
    }
  }
}
button {
  background: #000000;
  border-radius: 50px;
  width: calc(100% - 72px);
  color: white;
  font-size: 1rem;
  font-weight: 500;
  line-height: 150%;
  height: 3.1rem;
  border: none;
  margin: 25px 36px 14px;
  @media (min-width: 1100px) {
    width: 100%;
    margin: 60px 0 0;
    font-size: 22px;
    height: 4.8rem;
    filter: drop-shadow(0px 6.13861px 13.8119px rgba(240, 188, 176, 0.64));
  }
  @media (min-width: 1300px) and (max-width: 1550px) {
    margin: 40px 0 0;
  }
}

.back_btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #878787;
  text-align: center;
  cursor: pointer;
  @media (min-width: 1100px) {
    font-size: 22px;
    margin-top: 10px;
  }
}
.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin: 0;
  text-align: center;
  @media (min-width: 1100px) {
    font-size: 42px;
    line-height: 130%;
    text-align: right;
  }
}
.subtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0;
  text-align: center;
}
.placeholder {
  padding: 5px 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  color: #959595;
}
.error_msg {
  color: #de3730;
  position: absolute;
  top: 1.6rem;
  font-size: 0.8rem;
}
.footer_image {
  margin-bottom: 36px;
  margin-top: 20px;
  @media (min-width: 1100px) {
    display: none;
  }
}

.header_steps {
  display: flex;
  align-items: center;
  .step {
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #000000;
    &.active {
      background: #eb7255;
      color: white;
    }
  }
  .divider {
    width: 6px;
    height: 3px;
    background: white;
    border-radius: 4px;
    margin: 0 4px;
    &.active {
      background: #eb7255;
    }
  }
}

.add-form-step {
  padding: 0 36px 15px;
  @media (min-width: 1100px) {
    padding: 0 0 15px;
  }
  .main_logo {
    margin: 28px 30%;
    width: 40%;
    @media (min-width: 1100px) {
      margin: 0 0 75px;
      width: 140px;
    }
    @media (min-width: 1300px) and (max-width: 1550px) {
      margin: 0 0 50px;
    }
  }
  .header-image {
    width: calc(100% + 72px);
    margin-right: -36px;
    margin-top: 35px;
    @media (min-width: 1100px) {
      display: none;
    }
  }
  .subtitle {
    margin-top: 0;
    @media (min-width: 1100px) {
      font-size: 22px;
      line-height: 150%;
      text-align: right;
      margin-top: 28px;
    }
  }
  .form {
    margin-top: 40px;
    @media (min-width: 1100px) {
      margin-top: 60px;
    }
    @media (min-width: 1300px) and (max-width: 1550px) {
      margin-top: 40px;
    }
    label {
      position: relative;
      width: 100%;
      display: block;
      &:not(:first-child) {
        margin-top: 30px;
        @media (min-width: 1100px) {
          margin-top: 40px;
        }
      }
    }
    input, textarea {
      padding: 5px 0;
      color: #959595;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      width: 100%;
      border-width: 0 0 1px;
      border-color: #d9d9d9;
      @media (min-width: 1100px) {
        font-size: 18px;
        line-height: 150%;
      }
    }
    .placeholder {
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      color: #d9d9d9;
    }
    input:focus,
    textarea:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }
    input:focus + .placeholder,
    input:not(:placeholder-shown) + .placeholder,
    textarea:focus + .placeholder,
    textarea:not(:placeholder-shown) + .placeholder {
      // opacity:1;
      transform: scale(1) translateY(-60%) translateX(0);
    }
  }
}

.result_step {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    margin: 35px 0;
    max-width: 100%;
    max-height: 64px;
    @media (min-width: 1100px) {
      max-height: 100px;
    }
  }
  .actions_block {
    padding: 26px 14px;
    background: #ffffff;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 4px 5px rgba(222, 222, 222, 0.25);
    border-radius: 17px;
    text-align: center;
    .decription {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #000000;
      text-align: right;
      @media (min-width: 1100px) {
        font-size: 18px;
      }
    }
    .actions {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin-top: 24px;
      img {
        cursor: pointer;
      }
    }
  }
  &.last {
    text-align: center;
    .logo {
      margin: 10px 0;
    }
    .title {
      font-weight: 600;
      font-size: 23px;
      line-height: 140%;
      color: #000000;
      margin-bottom: 22px;
    }
    .notice {
      font-size: 11px;
      line-height: 160%;
      padding: 10px 12px;
      border-bottom: 1px solid #d9d9d9;
      @media (min-width: 1100px) {
        font-size: 14px;
      }
    }
    .subtitle {
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
      color: #000000;
      margin: 25px 0 29px;
      @media (min-width: 1100px) {
        font-size: 18px;
      }
    }
    .info {
      font-weight: 400;
      font-size: 11px;
      line-height: 160%;
      color: #000000;
      margin-top: 29px;
      @media (min-width: 1100px) {
        font-size: 14px;
        padding: 0 10%;
      }
    }
    .socials {
      direction: ltr;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;
      .email{
        margin-top: -5px;
        cursor: pointer;
      }
    }
  }
}

.accent {
  width: 20%;
  position: absolute;
  top: -6px;
  right: -8px;
  @media (min-width: 1100px) {
    display: none;
  }
}

.company_step {
  padding: 46px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 1100px) {
    padding: 0 0 10px;
  }
  .logo {
    width: auto;
    max-width: 100%;
    align-self: center;
    max-height: 64px;
    @media (min-width: 1100px) {
      max-height: 100px;
    }
  }
  .link_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
    a {
      direction: ltr;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #2a72f1;
      @media (min-width: 1100px) {
        font-size: 16px;
      }
    }
    img {
      width: 23px;
      filter: invert(50%) sepia(73%) saturate(4919%) hue-rotate(205deg)
        brightness(93%) contrast(103%);
    }
  }
  .message {
    margin-top: 28px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #000000;
    @media (min-width: 1100px) {
      font-size: 18px;
    }
  }
  .tags_block {
    margin-top: 20px;
  }
  .tags_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    align-items: center;
    color: #000000;
    @media (min-width: 1100px) {
      font-size: 22px;
    }
  }
  .tags_hint{
    margin-bottom: 10px;
    display: block;
    font-size: 12px;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    justify-content: center;
    .tag {
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #787878;
      padding: 8px 14px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 4px 5px rgba(222, 222, 222, 0.25);
      border-radius: 30px;
      cursor: pointer;
      &.active {
        background: #eb7255;
        color: white;
      }
      @media (min-width: 1100px) {
        font-size: 16px;
      }
    }
    &.not_choose {
      filter: drop-shadow(0px 6.13861px 13.8119px rgba(240, 188, 176, 0.64));
    }
  }
}

.tags_header {
  position: relative;
  // padding: 30px 0;
  img {
    max-width: 85px;
    position: absolute;
    left: -36px;
    top: -36px;
    transform: scaleX(-1);
    @media (min-width: 1100px) {
      display: none;
    }
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    @media (min-width: 1100px) {
      font-size: 22px;
      br {
        // display: none;
        content: "";
        &:after {
          content: " ";
        }
      }
    }
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    margin-top: 15px;
    @media (min-width: 1100px) {
      font-size: 16px;
    }
  }
  &.file {
    padding: 24px 0;
    @media (min-width: 1100px) {
      padding: 0 0 40px;
    }
  }
  &.message {
    padding: 0 0 45px;
    textarea {
      margin-top: 5px;
      width: 100%;
      padding: 0;
      border: none;
      border-bottom: 1px solid #000000;
      font-family: "Rubik", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #959595;
      &::placeholder {
        // font-size: 12px;
    }
    }
    input {
      padding: 5px 0;
      color: #959595;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      width: 100%;
      border-width: 0 0 1px;
      border-color: #d9d9d9;
      border-bottom: 1px solid #000000;
    }
    textarea,
    input {
      &::-webkit-input-placeholder {
        /* Edge */
        color: #959595;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #959595;
      }
      &::placeholder {
        color: #959595;
      }
      &:focus {
        &::placeholder {
          color: transparent;
        }
      }
      @media (min-width: 1100px) {
        font-size: 16px;
      }
    }
  }
  &.links {
    .title {
      text-align: center;
    }
    .subtitle {
      text-align: center;
      margin-top: 0;
    }
  }
}

.instruction {
  display: block;
  margin: 10px 0 0;
  color: #959595;
  font-weight: 400;
  font-size: 10px;
  line-height: 150%;
}

.tags,
.additional-tags {
  .title {
    font-size: 16px;
    line-height: 150%;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 7px;
    margin: 15px 0;
    @media (min-width: 1100px) {
      margin: 25px 0;
    }
    .tag {
      display: flex;
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 4px 5px rgba(222, 222, 222, 0.25);
      border-radius: 30px;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: #787878;
      padding: 8px 14px;
      cursor: pointer;
      &.active {
        background: #eb7255;
        color: white;
      }
      @media (min-width: 1100px) {
        font-size: 16px;
      }
      .delete {
        cursor: pointer;
        margin-right: 12px;
      }
    }
  }
}

#add-tag,
#add-link {
  display: block;
  width: calc(100% - 10px);
  border-width: 0 0 1px 0;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  color: #787878;
  padding: 5px;
  &:focus {
    outline: 1px solid #d9d9d9;
    border-radius: 5px;
  }
}

.dropzone {
  padding: 36px;
  background: #fafbfb;
  border: 1px dashed #000000;
  border-radius: 8px;
  text-align: center;
  margin-top: 5px;
  @media (min-width: 1100px) {
    padding: 30px;
    margin-top: 20px;
  }
  .btn {
    background: #e5effd;
    border-radius: 4px;
    padding: 4px 6px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }
  .allowed {
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6d7175;
  }
  img {
    position: inherit;
    max-width: 100%;
    margin: auto;
    display: block;
    transform: scale(1);
    max-height: 64px;
  }
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  label{
    position: relative;
  }
  input {
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #959595;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 30px;
    padding: 0 0 5px 0;
  }
}

.lang-en{
  direction: ltr;
  .title, .subtitle, .add-form-step .form input, .result_step .actions_block .decription {
    text-align: left;
  }
  .error_msg {
    left: 0;
  }
  .result_step.last .title, .company_step .tags_title, .company_step .tags_hint, .result_step.last .subtitle {
    text-align: center;
  }
  .desktop {
    @media (min-width: 1100px) {
      &[step="0"] {
        background-image: url(../Images/Desktop_Eng_2clickReview.jpg);
        background-position: right;
      }
    }
  }
  .wrapper[step="0"] {
    margin: auto 0 0 0;
}
input[type="tel"]{
  direction: ltr;
}
}

.notification-container{
  z-index: 100;
}